///
/// Faction by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			position: relative;
			margin: 0 0 (_size(element-margin) * 0.75) 0;
		}

		small {
			display: block;
			margin-bottom: 1em;
		}

		h1 + p,
		h2 + p {
			font-size: 1.25em;
			margin-top: (_size(element-margin) * -0.25);
		}
		h2 + p.post_subheader {
			font-size: 1.05em !important;
		}

		h3 + p {
			font-size: 1.1em;
			margin-top: (_size(element-margin) * -0.25);
		}

		h4 + p,
		h5 + p,
		h6 + p {
			font-size: 0.9em;
			margin-top: (_size(element-margin) * -0.25);
		}
	}

	@mixin color-section($p: null) {
		header {
			p {
				color: _palette($p, fg-fade);
			}
		}
	}

	@include color-section;