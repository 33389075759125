///
/// Faction by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Type */

	body {
		background-color: #f5f5f5;
		color: _palette(fg);
	}

	body, input, select, textarea {
		font-family: _font(family);
		font-size: 13pt;
		font-weight: _font(weight);
		letter-spacing: 0.02em;
		line-height: 1.85;

		@include breakpoint(xlarge) {
			font-size: 11.5pt;
		}

		@include breakpoint(large) {
			font-size: 11.5pt;
		}

		@include breakpoint(medium) {
			font-size: 12pt;
		}

		@include breakpoint(small) {
			font-size: 12pt;
		}

		@include breakpoint(xsmall) {
			font-size: 12pt;
		}

		@include breakpoint(xxsmall) {
			font-size: 11pt;
		}
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease-in-out',
			'border-bottom-color #{_duration(transition)} ease-in-out'
		));
		color: inherit;
		text-decoration: none;
		border-bottom: dotted 1px;

		&:hover {
			color: _palette(accent1, bg) !important;
			border-bottom-color: transparent;
		}
	}

	strong, b {
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		line-height: 1.4;

		a {
			border-bottom: 0;
		}
	}

	h1 {
		font-size: 2.5em;
		line-height: 1.1;
		margin-bottom: 0.5em;
	}

	h2 {
		font-size: 2em;
		line-height: 1.2;
		margin-bottom: 0.7em;
	}

	h3 {
		font-size: 1.5em;
		margin-bottom: 1em;
	}

	h4 {
		font-size: 1.25em;
		margin-bottom: 0.5em;
	}

	h5 {
		font-size: 0.9em;
		margin-bottom: 0.5em;
	}

	h6 {
		font-size: 0.7em;
		margin-bottom: 0.5em;
	}

	small {
		font-size: 0.875em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid (_size(border-width) * 4);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		border-radius: _size(border-radius);
		border: solid _size(border-width);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid _size(border-width);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@include breakpoint(large) {
		h1 {
			font-size: 2.2em;
		}

		h2 {
			font-size: 1.8em;
		}

		h3 {
			font-size: 1.35em;
		}

		h4 {
			font-size: 1.1em;
		}
	}

	@include breakpoint(small) {
		h1 {
			font-size: 2em;
		}

		h3 {
			line-height: 1.3;
		}
	}

	@mixin color-typography($p: null) {
		$highlight: _palette($p, highlight);

		@if $p != null {
			background-color: _palette($p, bg);
			color: _palette($p, fg);
		}

		input, select, textarea {
			color: _palette($p, fg-bold);
		}

		a {
			@if $p == $highlight {
				color: _palette($p, fg-bold);
			}
			@else {
				color: _palette(accent1, bg);
			}
		}

		strong, b {
			color: _palette($p, fg-bold);
		}

		small {
			color: _palette($p, fg-light);
		}

		h1, h2, h3, h4, h5, h6 {
			@if $p == $highlight {
				color: _palette($p, fg-bold);
			}
			@else {
				color: _palette(accent1, bg);
			}
		}

		blockquote {
			border-left-color: _palette($p, border);
		}

		code {
			background: _palette($p, border-bg);
			border-color: _palette($p, border);
		}

		hr {
			border-bottom-color: _palette($p, border);
		}
	}

	@include color-typography;